@import "../constants";

.ResultContainer {
  margin: $margin-l auto;
}

.ResultTitle {
  font-size: 2rem;
  text-align: center;
}

.Result {
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
  margin: $margin-m;
}

.ResultSmall {
  font-size: 2rem;
  text-align: center;
  font-weight: 700;
  margin: $margin-m;
}

.Disclaimer {
  font-size: 0.75rem;
  max-width: 384px;
  margin: auto;
  text-align: center;
}

.CTA-Container {
  margin: $margin-l auto;
}

.LinkButton {
  position: relative;
  display: inline-block;
  font-weight: 700;
  font-size: 1rem;
  background-color: $color-white;
  color: $color-orange;
  padding: 0.5rem;
  border: 2px $color-orange solid;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);

  &:hover {
    background-color: $color-orange;
    color: $color-white;
    text-decoration: none;
  }

  &:first-of-type {
    margin-bottom: $margin-s;
  }
}

.HubSpotForm {
  margin: $margin-l auto;
}

.ActionButton {
  margin-bottom: $margin-s;
  background-color: $color-orange;
  color: $color-white;
  &:hover {
    color: $color-orange;
    background-color: $color-white;
  }
}
