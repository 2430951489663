@import "constants";

p, h1, h2, h3, label {
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3 {
  font-weight: 700;
  margin-bottom: $margin-s;
}

h2 {
  font-size: 2rem;
  margin-top: $margin-l;
}

h3 {
  font-size: 1.5rem;
  padding-top: 2rem;
  margin-bottom: 1.5rem;
}

.container {
  max-width: 768px;
  border-bottom: 2px #EFE9E7 solid;
}

.Description {
  margin-bottom: $margin-s;
}
