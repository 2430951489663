@import "../constants";

.form-check {
  margin-bottom: $margin-m;
  padding-left: $margin-m;

  &:last-of-type {
    margin-bottom: $margin-l;
  }
}

.rad {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  position: relative;
  width: 100%;

  > input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + i {
      box-shadow: inset 0 0 0 3px #fff;
      background: $color-orange;
    }
  }

  > i {
    position: absolute;
    top: 4px;
    left: -2rem;
    display: inline-block;
    width:  16px;
    height: 16px;
    border-radius: 50%;
    transition: 0.2s;
    box-shadow: inset 0 0 0 8px #fff;
    border: 1px solid #333333;
  }

  &:hover > i {
    box-shadow: inset 0 0 0 3px #fff;
    background: #333333;
  }
}
